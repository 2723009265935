import React, { useEffect } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const handleEsc = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose && onClose();
      }
    },
    [onClose]
  );

  // watch ESC key to close the modal
  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen, onClose, handleEsc]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg p-4 z-10 max-w-3xl">
        {onClose && (
          <button
            className="absolute top-2 right-2 text-gray-600"
            onClick={onClose}
          >
            &times;
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
