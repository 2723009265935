import { useState, useMemo, useCallback } from "react";
import { teamMatchSchema } from "assets/constants/teamMatchQuestions";
import { TEvent, TTeamMatchQuestion } from "@services/types";
import { Link, useOutletContext } from "react-router-dom";
import { updateEvent } from "../../services/events";
import { TEAM_MATCH_FEATURE_FLAG } from "../../constants";
import { Button } from "../ui";
import FeatureToggle from "./FeatureToggle";
import { useFieldArray, useForm } from "react-hook-form";
import { InputText, InputFile } from "../../components/ui/form";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";

const TEAM_MATCH_QUESTIONS_LIMIT = 15;

type TTeamMatchForm = {
  teamAName: string;
  teamAPhotoFile: FileList;
  teamAPhotoStoragePath: string;
  teamBName: string;
  teamBPhotoFile: FileList;
  teamBPhotoStoragePath: string;
  questions: TTeamMatchQuestion[];
};

export default function TeamMatch() {
  const { event } = useOutletContext() as { event: TEvent };
  const [activeQuestions, setActiveQuestions] = useState<string[]>(
    event.teamMatchGame?.questions?.map((q) => q.id) ?? []
  );

  const featureEnabled = event.betaFeatures?.includes(TEAM_MATCH_FEATURE_FLAG);
  const [isTeamMatchEnabled, setIsTeamMatchEnabled] = useState(
    featureEnabled as boolean
  );
  const [uploading, setUploading] = useState(false);

  const teamMatchQuestions = useMemo(() => {
    return teamMatchSchema.map((questionSchema) => {
      const teamMatchQuestion = event.teamMatchGame?.questions.find(
        (q) => q.id === questionSchema.id
      );

      if (!teamMatchQuestion) {
        return {
          ...questionSchema,
          teamA: "",
          teamB: "",
        };
      }

      return {
        ...questionSchema,
        ...teamMatchQuestion,
      };
    });
  }, [event.teamMatchGame]);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<TTeamMatchForm>({
    defaultValues: {
      teamAName: event.teamMatchGame?.teamAName ?? "Time da Noiva",
      teamAPhotoStoragePath: event.teamMatchGame?.teamAPhotoStoragePath ?? "",
      teamBName: event.teamMatchGame?.teamBName ?? "Time do Noivo",
      teamBPhotoStoragePath: event.teamMatchGame?.teamBPhotoStoragePath ?? "",
      questions: teamMatchQuestions,
    },
  });

  const { fields: questionFields } = useFieldArray({
    control,
    name: "questions",
    keyName: "elementId",
  });

  const onSubmit = useCallback(
    async (data: TTeamMatchForm) => {
      if (isTeamMatchEnabled) {
        const betaFeatures = new Set(event.betaFeatures);
        betaFeatures.add(TEAM_MATCH_FEATURE_FLAG);
        event.betaFeatures = Array.from(betaFeatures);

        const {
          questions,
          teamAName,
          teamAPhotoStoragePath,
          teamBName,
          teamBPhotoStoragePath,
        } = data;
        const activeQuestionsFromForm = questions.filter((q) =>
          activeQuestions.includes(q.id)
        );

        event.teamMatchGame = {
          teamAName,
          teamAPhotoStoragePath,
          teamBName,
          teamBPhotoStoragePath,
          questions: activeQuestionsFromForm,
        };

        console.log("event", event.teamMatchGame);
      } else {
        // Remove teamMatchGame
        event.betaFeatures = event.betaFeatures?.filter(
          (f) => f !== TEAM_MATCH_FEATURE_FLAG
        );
      }

      await updateEvent(event);
    },
    [event, isTeamMatchEnabled, activeQuestions]
  );

  const handleQuestionToggle = (questionId: string) => {
    setActiveQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((id) => id !== questionId);
      }
      if (prev.length >= TEAM_MATCH_QUESTIONS_LIMIT) {
        alert(
          `Você atingiu o limite máximo de ${TEAM_MATCH_QUESTIONS_LIMIT} questões!`
        );
        return prev;
      }
      return [...prev, questionId];
    });
  };

  const handleShowFile = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>, storageKey: string) => {
      e.preventDefault();

      const fileUrl = await DigitalOceanSpaces.getFileUrl(storageKey);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    },
    []
  );

  const handleFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setUploading(true);

      const fileInput = e.target;

      if (fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];
        const fileInputName = fileInput.getAttribute("data-key") as
          | "teamAPhotoStoragePath"
          | "teamBPhotoStoragePath";
        const team = fileInput.getAttribute("data-team");
        const fileExt = file.name.split(".").pop();
        const fileStoragePath = `teamMatch/${event.id}/${team}.${fileExt}`;

        await DigitalOceanSpaces.uploadFile(file, fileStoragePath);

        setValue(fileInputName, fileStoragePath);

        setUploading(false);
      }
    },
    [event.id, setValue, setUploading]
  );

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Team Match
        <FeatureToggle
          checked={isTeamMatchEnabled}
          onChange={setIsTeamMatchEnabled}
        />
      </h1>

      <div className="flex items-center gap-2">
        <h3 className="">
          {activeQuestions.length}/{TEAM_MATCH_QUESTIONS_LIMIT} questões
          selecionadas
        </h3>
      </div>

      <form
        className="flex flex-col justify-start gap-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="w-full flex">
          <InputText
            label="Nome do Time A"
            register={register("teamAName")}
            field="teamAName"
            type="text"
            error={errors?.teamAName?.message}
            isEditable={isTeamMatchEnabled}
          />

          <InputFile
            label="Foto do Time A"
            register={register("teamAPhotoFile")}
            field="teamAPhotoFile"
            error={errors?.teamAPhotoFile?.message}
            isEditable={isTeamMatchEnabled}
            accept="image/*"
            onChange={handleFileUpload}
            data-team="team-a"
            data-key="teamAPhotoStoragePath"
            hint={
              event?.teamMatchGame?.teamAPhotoStoragePath && (
                <Link
                  to="#"
                  onClick={(e) =>
                    handleShowFile(
                      e,
                      event?.teamMatchGame?.teamAPhotoStoragePath as string
                    )
                  }
                  className="ml-3"
                >
                  Visualizar arquivo
                </Link>
              )
            }
          />
        </div>
        <div className="w-full flex">
          <InputText
            label="Nome do Time B"
            register={register("teamBName")}
            field="teamBName"
            type="text"
            error={errors?.teamBName?.message}
            isEditable={isTeamMatchEnabled}
          />

          <InputFile
            label="Foto do Time B"
            register={register("teamBPhotoFile")}
            field="teamBPhotoFile"
            error={errors?.teamBPhotoFile?.message}
            isEditable={isTeamMatchEnabled}
            accept="image/*"
            onChange={handleFileUpload}
            data-team="team-b"
            data-key="teamBPhotoStoragePath"
            hint={
              event?.teamMatchGame?.teamBPhotoStoragePath && (
                <Link
                  to="#"
                  onClick={(e) =>
                    handleShowFile(
                      e,
                      event?.teamMatchGame?.teamBPhotoStoragePath as string
                    )
                  }
                  className="ml-3"
                >
                  Visualizar arquivo
                </Link>
              )
            }
          />
        </div>
        <div>
          {questionFields.map((question, index) => (
            <TeamMatchQuestion
              key={question.elementId}
              question={question}
              register={register}
              index={index}
              errors={errors}
              setActiveQuestions={handleQuestionToggle}
              isActive={activeQuestions.includes(question.id)}
              disabled={!isTeamMatchEnabled}
            />
          ))}
        </div>

        <div className="pt-5">
          <Button type="submit" disabled={uploading}>
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}

type TTeamMatchQuestionProps = {
  question: TTeamMatchQuestion;
  register: any;
  index: number;
  errors?: any;
  setActiveQuestions: (questionId: string) => void;
  isActive: boolean;
  disabled?: boolean;
};

const TeamMatchQuestion = (props: TTeamMatchQuestionProps) => {
  const {
    register,
    question,
    index,
    errors,
    setActiveQuestions,
    isActive,
    disabled = false,
  } = props;
  const questionNumber = index + 1;
  const answersRequired = !disabled && isActive;

  return (
    <fieldset
      className="flex flex-col justify-start gap-2 w-full"
      disabled={disabled}
    >
      <legend className="flex items-center justify-start gap-4 w-full">
        <input
          name={`questions.${index}.enabled`}
          type="checkbox"
          checked={isActive}
          onChange={(e) => setActiveQuestions(question.id)}
          className="w-6 h-6"
          disabled={disabled}
        />
        <h2 className="font-bold">{`${questionNumber}. ${question.text}`}</h2>
      </legend>

      <div className="flex items-center justify-start gap-2 w-full">
        <InputText
          label="Resposta Time A"
          register={register(`questions.${index}.teamA`, {
            required: answersRequired,
          })}
          field={`questions.${index}.teamA`}
          type="text"
          error={errors?.questions?.[index]?.teamA?.message}
          isEditable={!disabled}
        />

        <InputText
          label="Resposta Time B"
          register={register(`questions.${index}.teamB`, {
            required: answersRequired,
          })}
          field={`questions.${index}.teamB`}
          type="text"
          error={errors?.questions?.[index]?.teamB?.message}
          isEditable={!disabled}
        />
      </div>
    </fieldset>
  );
};
