import { useState } from "react";
import { Button } from "../ui";
import { useOutletContext } from "react-router-dom";
import { TEvent } from "@services/types";
import { FEED_FEATURE_FLAG } from "../../constants";
import { updateEvent } from "../../services/events";
import FeatureToggle from "./FeatureToggle";

export default function Feed() {
  const { event } = useOutletContext() as { event: TEvent };

  const featureEnabled = event.betaFeatures?.includes(FEED_FEATURE_FLAG);
  const [isEnabled, setIsEnabled] = useState(featureEnabled as boolean);

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isEnabled) {
      event.betaFeatures = event.betaFeatures
        ? [...event.betaFeatures, FEED_FEATURE_FLAG]
        : [FEED_FEATURE_FLAG];
    } else {
      event.betaFeatures = event.betaFeatures?.filter(
        (feature) => feature !== FEED_FEATURE_FLAG
      );
    }

    await updateEvent(event);
  };

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Feed da Festa
        <FeatureToggle checked={isEnabled} onChange={setIsEnabled} />
      </h1>

      <form
        className="flex flex-col justify-start gap-4 w-full"
        onSubmit={handleSave}
      >
        <div className="pt-5">
          <Button type="submit">Salvar</Button>
        </div>
      </form>
    </div>
  );
}
