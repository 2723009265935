import React from "react";
import Header from "./Header";
import { Outlet, useLoaderData } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";
import Footer from "./Footer";
import { TEvent } from "@services/types";

export default function AuthenticatedPanelLayout() {
  const { event } = useLoaderData() as { event: TEvent };

  if (process.env.NODE_ENV === "production") {
    clarity.init("ngjj6db2ll");
  }

  console.log("event", event);

  return (
    <React.StrictMode>
      <div className="theme-layout">
        <Header event={event} />

        <div className="theme-body">
          <div className="theme-container">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </React.StrictMode>
  );
}
