export const MONTHS_UNTIL_CLOSE_DOWNLOADS = 6;

export const VIDEO_FILE_EXTENSIONS = ["mp4", "webm", "mov"];

export const BINGO_FEATURE_FLAG = "bingo";

export const QUIZ_FEATURE_FLAG = "quiz";

export const PHOTO_CHALLENGE_FEATURE_FLAG = "photo_challenge";

export const TEAM_MATCH_FEATURE_FLAG = "team_match";

export const UPLOADS_IN_MENU_FEATURE_FLAG = "uploads_in_menu";

export const FEED_FEATURE_FLAG = "feed";

export const GAMES_AVAILABLE = [
  BINGO_FEATURE_FLAG,
  QUIZ_FEATURE_FLAG,
  PHOTO_CHALLENGE_FEATURE_FLAG,
  TEAM_MATCH_FEATURE_FLAG,
];

export const QUIZ_RESULTS_COLLECTION = "quizResults";

export const PHOTO_CHALLENGES_DONE_COLLECTION = "photoChallengesDone";

export const TEAM_MATCH_RESULTS_COLLECTION = "teamMatchResults";

export const STANDARD_FILE_EXTENSIONS = ["jpg", "jpeg", "png", "mp4"];
