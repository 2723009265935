type InputProps = {
  label: string | JSX.Element;
  register: any;
  error?: any;
  field: string;
  type?: string;
  defaultValue?: string;
  options?: { label: string; value: string }[];
  hint?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputCheckbox({
  label,
  register,
  error,
  field,
  type,
  value,
  defaultChecked,
  hint,
  ...rest
}: InputProps) {
  const id = `${field}-${value}`;
  const key = typeof label === "string" ? label : "";

  return (
    <div>
      <label
        key={key}
        htmlFor={id}
        className="flex items-center justify-start gap-2"
      >
        <input
          type="checkbox"
          {...register}
          name={field}
          id={id}
          value={value}
          className="focus:outline-none focus:ring-0 w-5 h-5"
          {...rest}
        />
        {label}
      </label>
      {hint && <span className="text-xs text-gray-500">{hint}</span>}
    </div>
  );
}

export default InputCheckbox;
