import {
  Bingo,
  PhotoChallenge,
  Quiz,
  TeamMatch,
  Feed,
  UploadMenu,
} from "../../../components/beta";
import { useState } from "react";

export default function NewFeatures() {
  const [activeTab, setActiveTab] = useState("bingo");

  return (
    <div className="w-full flex flex-col justify-start bg-white">
      <nav className="flex items-center justify-start gap-5 bg-gray-100 p-4 rounded-lg">
        <div
          className={`text-xl cursor-pointer ${
            activeTab === "bingo" ? "text-red-400 font-bold" : ""
          }`}
          onClick={() => setActiveTab("bingo")}
        >
          Bingo
        </div>
        <div
          className={`text-xl cursor-pointer ${
            activeTab === "quiz" ? "text-red-400 font-bold" : ""
          }`}
          onClick={() => setActiveTab("quiz")}
        >
          Quiz
        </div>
        <div
          className={`text-xl cursor-pointer ${
            activeTab === "photo_challenge" ? "text-red-400 font-bold" : ""
          }`}
          onClick={() => setActiveTab("photo_challenge")}
        >
          Foto Desafio
        </div>
        <div
          className={`text-xl cursor-pointer ${
            activeTab === "team_match" ? "text-red-400 font-bold" : ""
          }`}
          onClick={() => setActiveTab("team_match")}
        >
          Team Match
        </div>
        <div
          className={`text-xl cursor-pointer ${
            activeTab === "feed" ? "text-red-400 font-bold" : ""
          }`}
          onClick={() => setActiveTab("feed")}
        >
          Feed da Festa
        </div>
        <div
          className={`text-xl cursor-pointer ${
            activeTab === "upload_menu" ? "text-red-400 font-bold" : ""
          }`}
          onClick={() => setActiveTab("upload_menu")}
        >
          Menu
        </div>
      </nav>
      <div className="flex flex-col items-start justify-start p-10">
        <section className="">
          {activeTab === "bingo" && <Bingo />}
          {activeTab === "quiz" && <Quiz />}
          {activeTab === "photo_challenge" && <PhotoChallenge />}
          {activeTab === "team_match" && <TeamMatch />}
          {activeTab === "feed" && <Feed />}
          {activeTab === "upload_menu" && <UploadMenu />}
        </section>
      </div>
    </div>
  );
}
