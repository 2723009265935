import { TEventWithUploads } from "@services/types";
import { useLoaderData } from "react-router-dom";
import { eventTitleFormatter, isVideo } from "utils/event";
import { GalleryWithSlideshow } from "components/gallery";
import { useMemo } from "react";

export default function PublicGallery() {
  const event = useLoaderData() as TEventWithUploads;
  const eventTitle = eventTitleFormatter(event);
  const imageUploads = useMemo(
    () => event.uploads.filter((upload) => !isVideo(upload.fileExt)),
    [event.uploads]
  );

  return (
    <div className="w-full flex flex-col items-center mt-10 mb-16">
      <h1 className="text-3xl text-center font-playfair-display font-normal">
        {eventTitle}
      </h1>

      <div className="w-full mt-10">
        <GalleryWithSlideshow event={event} uploads={imageUploads} />
      </div>
    </div>
  );
}
