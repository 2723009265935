type InputProps = {
  label?: string;
  register: any;
  error: any;
  field: string;
  type?: string;
  defaultValue?: string;
  options?: { label: string; value: string }[];
  display?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputRadio({
  label,
  register,
  error,
  field,
  type,
  options,
  display = "row",
  ...rest
}: InputProps) {
  let displayClass = "flex items-center gap-5 mt-1";

  if (display === "column") {
    displayClass = "flex flex-col items-start";
  }

  return (
    <div className="w-full flex flex-col items-start">
      {label && (
        <label className="flex items-center gap-1.5 text-xs font-century-gothic-bold">
          {label}
          {error && <span className="text-red-500">[{error.message}]</span>}
        </label>
      )}

      <div className={displayClass}>
        {options?.map((option) => {
          return (
            <label
              key={option.value}
              htmlFor={option.value}
              className="flex items-center justify-start gap-1"
            >
              <input
                type="radio"
                id={option.value}
                {...register}
                {...rest}
                value={option.value}
                defaultChecked={option.value === rest.value}
                className="focus:outline-none focus:ring-0 w-5"
              />
              {option.label}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default InputRadio;
